<template>
  <div class="edit-layout-actions">
    <!-- Create Element -->
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-tooltip.hover
      :title="$t('elements.create')"
      variant="primary"
      target="_blank"
      class="layout-action"
      :disabled="isSavingLayout"
      @click="isCreateElementModalVisible=true"
    >
    <feather-icon icon="PlusIcon"/>
      <h6 class="text-white mb-0 ml-50 mr-50">
        {{ $t('elements.create') }}
      </h6>
    </b-button>
    <!-- Save Layout -->
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-tooltip.hover
      :title="$t('header.action.save-layout')"
      variant="primary"
      target="_blank"
      class="layout-action"
      :disabled="isSavingLayout"
      @click="handleSaveEditLayout"
    >
      <feather-icon icon="SaveIcon" />
      <h6 class="text-white mb-0 ml-50 mr-50">
        {{ $t('header.action.save-layout') }}
      </h6>
    </b-button>
    <!-- Cancel Layout Edit -->
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-tooltip.hover
      :title="$t('action.cancel')"
      variant="primary"
      target="_blank"
      class="layout-action"
      :disabled="isSavingLayout"
      @click="handleCancelEditLayout"
    >
      <feather-icon icon="XCircleIcon" />
      <h6 class="text-white mb-0 ml-50 mr-50">
        {{ $t('action.cancel') }}
      </h6>
    </b-button>
    <!-- Create Element Modal -->
    <modal-create-element 
      v-model="isCreateElementModalVisible" 
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import ModalCreateElement from '@core/components/layouts/apps-layout/elements-layout/ModalCreateElement.vue';
import { ENABLED_APPS_ACTIONS, ENABLED_APPS_MUTATIONS } from '@/store/enabled-apps/enabled-apps-store-constants';

export default {
  name: 'EditLayoutActions',
  directives: {
    Ripple,
  },
  components: { ModalCreateElement },
  mixins: [ToastNotificationsMixin],
  emits: ['toggle-layout-edited'],
  data() {
    return {
      isSavingLayout: false,
      message: this.$t('header.action.alert-layout-template'),
      isCreateElementModalVisible: false
    };
  },
  computed: {
    //
  },
  methods: {
    async handleSaveEditLayout() {
      try {
        this.isSavingLayout = true;
        await this.$store.dispatch(ENABLED_APPS_ACTIONS.saveLayout);
        this.notifySuccess(this.$t('layouts.message.saved-success'));
        this.toggleLayoutEdited();
        //console.log('layout edited! emiting to parent: toggle-layout-edited');
        this.$emit('toggle-layout-edited');
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
      this.isSavingLayout = false;
      //TODO: reload only if edited elements
      window.location.reload(); //TODO: this reload is made as a patch until the previous emit is catched by the parent
    },
    toggleLayoutEdited() {
      this.$emit('toggle-layout-edited');
    },
    handleCancelEditLayout() {
      this.$store.commit(ENABLED_APPS_MUTATIONS.cancelEditLayout);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.layout-action {
  padding: 0.5rem;
  margin-left: 0.5rem;
  display: flex;
  &:first-child {
    margin-left: 0;
  }
  @include media-breakpoint-up(md) {
    padding: 0.7rem;
  }
}

.edit-layout-actions {
  display: flex;
}

.buttons-pack {
  display: flex;
}
</style>
